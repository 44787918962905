<template>
    <div class="home">
        <x-header>项目浏览</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <div class="search_warp">
                <el-row class="list-control_warp">
                    <el-col :span="24">
                        <el-col :span="6">
                            <el-input alwaysShowClear clearable v-model="searchText" @keydown.enter.native="search" placeholder="输入项目名称">
                                <template slot="suffix">
                                    <i @click="search" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
                                </template>
                            </el-input>
                        </el-col>
                    </el-col>
                </el-row>
            </div>
            <x-card>
                <el-table
                    :data="groupDataList"
                    stripe
                    style="width: 100%">
                    <el-table-column
                        prop="projectCode"
                        label="项目编号">
                    </el-table-column>
                    <el-table-column
                        label="项目名称">
                        <template slot-scope="scope">
                                    {{scope.row.groupName}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="负责人">
                        <template slot-scope="scope">
							{{scope.row.groupManagerList?scope.row.groupManagerList.join(","):""}}
						</template>
                    </el-table-column>
                     <el-table-column
                        label="当前模板">
                         <template slot-scope="scope">
                            {{scope.row.formTemplateId?formTemplateNameMap[scope.row.formTemplateId]:''}}
						</template>
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="创建时间">
                    </el-table-column>
                     <el-table-column
                        prop="archivingTime"
                        label="归档时间">
                    </el-table-column>
                    <el-table-column
                        label="项目成员">
                         <template slot-scope="scope">
                            <el-tooltip content="查看成员" placement="bottom" >
                                <el-link type="primary"  style='color:#409EFF' @click="$router.push('/manager/groupUser/' + scope.row.id+'/1')" :underline="false">{{scope.row.countGroupUser}}</el-link>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                     <el-table-column
                        label="已提交实验">
                        <template slot-scope="scope" >
                            <el-tooltip content="查看实验" placement="bottom" >
                           <el-link type="primary" style='color:#409EFF' @click="$router.push('/manager/experiment/' + scope.row.id+'_5')" :underline="false">{{scope.row.countExperiment}}</el-link>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page-warp">
                    <el-pagination
                        @current-change="listGroup"
                        :hide-on-single-page="total<pageSize"
                        background
                        layout="total,prev, pager, next"
                        :total="total"
                        :page-size="pageSize"
                        :current-page.sync="page"
                    ></el-pagination>
                </div>
            </x-card>
        </x-content>
    </div>
</template>
<script>
    import { ListGroup} from "../../service/user"
        import {GetAllList} from "@/service/formTemplate";

    export default {
        name:'AuditGroupManager',
        data(){
            return {
                loading:false,
                page:1,
                total:0,
                pageSize:10,
                searchText:"",
                groupDataList:[],
                groupRole:"999",
                formTemplateNameMap:{},

  
            }
        },
        created(){
            this.listGroup();
            this.loadFormTemplate();

        },
        mounted(){
      
        },
        methods:{
            search() {
		        	this.page = 1;
		        	this.listGroup();
		        },
            listGroup(){
                this.loading = true;
                ListGroup(this.groupRole,this.searchText,this.page,this.pageSize).then(e => {
                    let data = this.APIParse(e);
                    this.groupDataList = data.records;
                    this.total = data.total || 0;
                }).catch(e => {
                    this.$message.error(e.message)
                }).finally(e => {
                    this.loading = false;
                })
            },
                     loadFormTemplate(){
                 GetAllList().then(e => {
                    let data = this.APIParse(e);
                     data.forEach(item => {
                      this.$set(this.formTemplateNameMap,item.id,item.name)
                    })
                }).catch(e => {
                    this.$message.error(e.message)
                }).finally(e => {
                   
                })
            },
    }  
}
</script>
<style lang="scss" scoped>
.el-table td.el-table__cell div .base-button--mini{
    padding: 0px 8px;
    margin-bottom: 5px;
}

    .Width-100{width: 100%}
    .home{width: 100%;height: 100%;overflow: auto;}
    .search_warp{width:100%;margin: 16px 0px;}
    
    .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
	.page-warp{width: 100%;text-align: right;margin-top: 8px;}

    	.setTooltip{
        margin-top: 30px;
        color:#409eff
    }
    .base-button{
        margin-left: 0px;
        margin-right: 5px;
    }

</style>
<style >
.templatePreviewdialog .AppDialog__inner{
	max-height: 80vm !important;
	overflow-y: scroll;
}
</style>